<template>
  <div class="main-box">
    <div style="height:60px"></div> <!-- 占位 -->
    <!-- 面包屑 -->
    <div class="menu-inner">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/homework/homeworkList">作业列表</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item style="color: #15b7dd">{{detail.homeworkTitle}}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <!-- 作业详情/记录面板 -->
    <div class="common-panel">
      <h4 class="title">作业详情</h4>
      <!-- 切换 -->
      <div class="tab-inner">
        <span :class="{'span-active':tabIndex == 1}" @click="onTab(1)">详情</span>
        <span :class="{'span-active':tabIndex == 2}" @click="onTab(2)">批阅</span>
      </div>

      <div style="margin-top: 25px;" v-show="tabIndex == 1 && !loading">
        <div class="detail-box">
          <!-- 提交说明 -->
          <div class="explain">
            <h4><img src="./icon/icon6.png" alt="">{{detail.homeworkTitle}}</h4>
            <div class="info" v-html="detail.answerNote"></div>
          </div>
          <!-- 作业详情 -->
          <div class="work-info" ref="hideText" id="hideText">
            <!-- 左 -->
            <div class="side-l" :class="{'hidden' : isShow}">
              <div class="html" v-html="detail.libraryContent"></div>
              <div class="file" v-if="detail.attachment.length">
                <File-List :list="detail.attachment" :isDown="1" />
              </div>
            </div>
            <!-- 右 -->
            <img class="icon" src="./icon/icon11.png" alt="">

            <div class="develop" v-if="isHide && isShow">
              <span @click="isShow = false">展开<a-icon type="down" /></span>
            </div>
          </div>
        </div>
        <div class="pack-up" v-if="isHide && !isShow">
          <span @click="isShow = true">收起<a-icon type="up" /></span>
        </div>
      </div>
      <div style="margin-top: 25px;" v-show="tabIndex == 2 && !loading">
        <!-- 反馈列表 -->
        <div class="feedback-list">
          <div class="side-item first-item">
            <div class="line-l"><i></i><div class="line"></div></div>
            <div class="box-r">
              <h4><img src="./icon/icon6.png" alt="">{{detail.homeworkTitle}}</h4>
            </div>
          </div>
          <div class="side-item" v-for="item in historyDate" :key="item.id">
            <div class="line-l"><i></i><div class="line"></div></div>
            <div class="box-r">
              <div class="content-inner">
                <!-- 状态和时间 -->
                <div class="top">
                  <h4>{{item.status == 1?'已提交':item.status == 2?'已驳回':item.status == 3?'已批阅':''}}</h4>
                  <div class="time">
                    <a-icon type="clock-circle" />
                    <span>{{item.createTime}}</span>
                  </div>
                </div>
 
                <!-- 已提交 -->
                <template v-if="item.status == 1">
                  <div class="txt">
                    {{item.answerContent}}
                  </div>
                </template>

                <!-- 已驳回 -->
                <template v-if="item.status == 2">
                  <div class="txt">
                    驳回原因：{{ item.reply ? item.reply : '-' }}
                  </div>
                </template>
                
                <!-- 已批阅 -->
                <template v-if="item.status == 3">
                  <p class="score" v-if="item.status == 3">评分：{{item.score ? item.score : '-'}}</p>
                  <div class="txt">
                    评语：{{ item.reply ? item.reply : '-' }}
                  </div>
                </template>

                <!-- 作业提交附件 -->
                <div class="homework-list" v-if="item.status == 1 && item.attachlist.length">
                  <div class="item-li" @click="onOpenFile(child.url)" v-for="(child,index) in item.attachlist" :key="index + 'li'">
                    <div>
                      <img :src="getSuffix(child.url)" alt="">
                      <span>{{child.name}}</span>
                    </div>
                    <a-icon style="color:#666666" type="right" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div>
        <a-spin class="sping" v-show="loading" />
      </div>
    </div>

    <!-- 提交作业附件 -->
    <div class="work-file" v-show="tabIndex == 1 && !loading">
      <div class="inner">
        <template v-if="detail.status == 5 || detail.status == 8">
          <div class="top">
            <p class="text1"><img :src=" detail.status == 5 ? errorIcon : successIcon" alt="">{{ detail.status == 5 ? '驳回原因' : '教师批阅'}}</p>
            <!-- 状态icon -->
            <img class="icon-type" v-if="detail.status == 1 || detail.status == 0" src="./icon/icon-type1.png" alt="">  <!-- 待提交 -->
            <img class="icon-type" v-if="detail.status == 5" src="./icon/icon-type3.png" alt="">  <!-- 已驳回 -->
            <img class="icon-type" v-if="detail.status == 8" src="./icon/icon-type4.png" alt="">  <!-- 已批阅 -->
          </div>
          <p class="info-score" v-if="detail.status == 8">评分：{{detail.score}}</p>
          <div class="reply">
            <span v-if="detail.status == 8">评语：</span>
            {{detail.reply}}
          </div>
        </template>
        <template>
          <div :class="{'top' : detail.status == 3}">
            <p class="text1"><img src="./icon/icon13.png" alt="">我的作业</p>
            <img class="icon-type" v-if="detail.status == 3 || detail.status == 7" src="./icon/icon-type2.png" alt="">  <!-- 待批阅 -->
          </div>

          <div class="reply">
            {{detail.answerContent}}
          </div>
          <div class="home-wrok">
            <!-- 作业提交附件 -->
            <template v-if="detail.attachlist.length">
              <File-List :list="detail.attachlist" :isDown="0" />
            </template>
          </div>
          <div class="home-wrok">
            <!-- 作业提交补充资料 -->
            <template v-if="detail.supplyAttach.length">
              <File-List :list="detail.supplyAttach" text="补充资料" :isDown="0" />
            </template>
          </div>
        </template>
      </div>
      <div class="time" v-if="detail.status == 1 || detail.status == 0 || detail.status == 5">
        <p class="hours">提交剩余时间：<i>{{day}}</i><span>天</span><i>{{hour}}</i><span>时</span><i>{{min}}</i><span>分</span><i>{{s}}</i><span>秒</span></p>
        <p class="all-btn-small" :class="{'disable':(!(funDateSize(detail.answerStartdate)) || funDateSize(detail.answerEnddate))}" @click="onSubmitPage()">
          {{detail.status == 5 ? '重新提交':'作业提交'}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { openFile,getSuffix } from '@/unit/fun.js'
import FileList from "./FileList.vue";

export default {
  // 可用组件的哈希表
  components: { FileList },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      errorIcon: require('./icon/icon8.png'),
      successIcon: require('./icon/icon9.png'),
      tabIndex: 1,
      loading:false,
      answerId: 0,
      detail:{
        attachlist:[],
        attachment:[],
        supplyAttach:[]
      },
      historyDate: [],
      isShow:false,
      isHide:false,
      isFirst:true,
      day: 0,
      hour: 0,
      min: 0,
      s: 0,
      Interval: null,
      time: 0,
    }
  },
  // 事件处理器
  methods: {
    // 提交作业
    onSubmitPage() {
      // 检测作答时间是否开始
      if(this.funDateSize(this.detail.answerStartdate)){
        // 检测作答时间是否结束
        if(!this.funDateSize(this.detail.answerEnddate)){
          this.$router.push({
            path: "/homework/homeworkSubmit?answerId=" + this.$route.query.answerId
          });
        } else {
          this.$message.error('作业作答时间已结束');
        }
      } else {
        this.$message.error('作业作答开始时间：' + e.answerStartdate);
      }
    },

    // 是否可以提交作业
    funDateSize(date1){
      var oDate1 = new Date(date1);
      var oDate2 = new Date();

      var isBegin = true
      if(oDate1.getTime() > oDate2.getTime()){
        isBegin = false
      } else {
        isBegin = true
      }
      return isBegin
    },

    onTab(i){
      this.tabIndex = i
      if(i == 1 && this.isFirst && this.$route.query.type == 2){
        this.isFirst = false
        this.$nextTick(()=>{
          const clientHeight = document.getElementById('hideText').clientHeight
          this.isShow = clientHeight > 300 ? true : false
          this.isHide = clientHeight > 300 ? true : false
        });
      }
    },

    // 打开文件
    onOpenFile(url){
      openFile(url)
    },

    // 解析文件后缀返回对应图片
    getSuffix(string){
      return getSuffix(string)
    },

    
    // 获取详情数据
    getHomeWorkData() {
      this.loading = true
      this.$ajax({
        url: '/hxclass-pc/homework/userAnswer/details',
        params: {
          answerId: this.answerId
        }
      }).then(res=>{
        this.loading = false
        if(res.code == 200 && res.success){
          res.data.attachlist = res.data.attachlist ? JSON.parse(res.data.attachlist) :[]
          res.data.attachment = res.data.attachment ? JSON.parse(res.data.attachment) :[]
          res.data.supplyAttach = res.data.supplyAttach ? JSON.parse(res.data.supplyAttach) :[]
          this.detail = res.data;

          // 开始提交作业且未到结束时间
          if(!res.data.overdue && this.btnShow(res.data.answerStartdate) && (res.data.status == 0 || res.data.status == 1 || res.data.status == 5)) {
            this.time = new Date((res.data.answerEnddate).replace(/-/g,'/')).getTime() - new Date().getTime();
            this.Interval = setInterval(()=>{
              if(this.time <= 0) {
                clearInterval(this.Interval);
                this.getHomeWorkData();
              }
              this.time -= 1000;
            },1000)
          }
          
          // 未到开始时间
          if(!this.btnShow(res.data.answerStartdate)){
            this.time = new Date((res.data.answerStartdate).replace(/-/g,'/')).getTime() - new Date().getTime();
            this.Interval = setInterval(()=>{
              if(this.time <= 0) {
                clearInterval(this.Interval);
                this.getHomeWorkData();
              }
              this.time -= 1000;
            },1000)
          }

          setTimeout(() => {
            const clientHeight = document.getElementById('hideText').clientHeight
            this.isShow = clientHeight > 400 ? true : false
            this.isHide = clientHeight > 400 ? true : false
          }, 400)
        }else {
        }
        this.getHistoryList();
      })
    },

    // 是否展示提交按钮
    btnShow(e) {
      if(new Date(e).getTime() > new Date().getTime()) {
        return false;
      }
      return true;
    },

    // 查询作业提交记录
    getHistoryList(){
      this.loading = true
      this.$ajax({
        url: '/hxclass-pc/homework/history/list',
        params: {
          answerId: this.answerId
        }
      }).then(res=>{
        this.loading = false
        if(res.code == 200 && res.success) {
          // 过滤显示已提交和已驳回的数据，已批阅需满足成绩发布展示
          let arry = []
          arry = res.data.filter(item=>{
            return item.status != 3 || (item.status == 3 && this.detail.status == 8);
          })
          arry.forEach(element => {
            if(element.attachlist){
              element.attachlist = JSON.parse(element.attachlist)
            } else {
              element.attachlist = []
            }
          });
          this.historyDate = arry;
        }else {

        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.tabIndex = this.$route.query.type
    this.answerId = this.$AES.decode_data(this.$route.query.answerId);
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getHomeWorkData()
  },
  beforeDestroy(){
    clearInterval(this.Interval);
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    time(newVal,oldVal){
			const time = newVal;
      let d = parseInt(time / 1000 / 3600 / 24);
      if (d < 10) {
        d = "0" + d;
      }
      let h = parseInt((time / 1000 / 60 / 60) % 24);
      if (h < 10) {
        h = "0" + h;
      }
      let m = parseInt((time / 1000 / 60) % 60);
      if (m < 10) {
        m = "0" + m;
      }
      let s = parseInt((time / 1000) % 60);
      if (s < 10) {
        s = "0" + s;
      }
      this.day = d;
      this.hour = h;
      this.min = m;
      this.s = s;
    }
  }
}
</script>

<style lang="less" scoped>
.menu-inner{
  /deep/ .ant-breadcrumb{
    line-height: 60px;
  }
}

.common-panel{
  background: #ffffff;
  padding: 30px;
  min-height: 500px;
  border-radius: 5px;
  position: relative;
  .title{
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #15B7DD;
    line-height: 23px;
    &::before{
      content: '';
      display: inline-block;
      width: 3px;
      height: 16px;
      background: #15B7DD;
      border-radius: 2px;
      margin-right: 4px;
      margin-bottom: -1px;
    }
  }

  .tab-inner{
    display: inline-block;
    margin-top: 24px;
    background: rgba(21,183,221,0.1);
    padding: 0 30px;
    margin-left: -30px;
    >span{
      display: inline-block;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 38px;
      width: 104px;
      cursor: pointer;
    }
    >span:first-child{
      margin-right: 40px;
    }
    .span-active{
      background: @theme;
      color: #ffffff;
    }
  }

  .detail-box{
    border: 1px solid #F2F2F2;
    border-radius: 10px;
    overflow: hidden;
    .explain{
      padding: 16px;
      background: #FCFBFC;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 25px;
      img{
        width: 13px;
        margin-bottom: 3px;
        margin-right: 4px;
      }
      >h4{
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }
      .info{
        margin-top: 8px;
        margin-left: 18px;
        line-height: 22px;
        /deep/strong{
          font-weight: bold;
        }
        /deep/em{
          font-style: italic;
        }
        /deep/img{
          max-width: 100%;
        }
        /deep/.remarks{
          max-width: 100%;
          height: auto !important;
          -webkit-line-clamp: 10000 !important;
        }
        /deep/ video{
          width: 100%;
        }
      }
    }
    .work-info{
      margin-left: 33px;
      position: relative;
      min-height: 200px;
      padding-bottom: 16px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 25px;
      .side-l{
        // width: 830px;
        .html{
          padding-top: 12px;
          margin-bottom: 20px;
          /deep/strong{
            font-weight: bold;
          }
          /deep/em{
            font-style: italic;
          }
          /deep/img{
            max-width: 100%;
          }
          /deep/.remarks{
            max-width: 100%;
            height: auto !important;
            -webkit-line-clamp: 10000 !important;
          }
          /deep/ video{
            width: 100%;
          }
        }  
        .file{
          margin-right: 30px;
          border-top: 1px solid #F2F2F2;
        }  
      }
      .hidden{
        height: 300px;
        overflow: hidden;
      }
      .icon{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 214px;
        height: 214px;
      }
      .develop{
        position: absolute;
        bottom: 14px;
        right: 16px;
        text-align: center;
        width: 100%;
        height: 52px;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.55) 29%, #FFFFFF 100%);
        border-radius: 0px 0px 10px 10px;
        span{
          display: inline-block;
          color: #666666;
          margin-top: 20px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 18px;
          cursor: pointer;
        }
        img{
          width: 8px;
        }
      }
    }
  }
  .pack-up{
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
  }

  .feedback-list{
    .side-item{
      display: flex;
      margin-bottom: 26px;
      .line-l{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 14px;
        i{
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-bottom: 6px;
          border: 2px solid #15B7DD;
          border-radius: 50%;
        }
        .line{
          width: 1px;
          background: #D9D9D9;
          height: 100%;
        }
      }
      .box-r{
        flex: 1;
        margin-left: 9px;
        h4{
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 24px;
          img{
            width: 14px;
            margin-bottom: 2px;
            margin-right: 6px;
          }
        }
        .content-inner{
          border-radius: 10px;
          padding: 12px 20px;
          background: #FCFBFC;
          .top{
            display: flex;
            justify-content: space-between;
            .time{
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 18px;
              span{
                margin-left: 4px;
              }
            }
          }
          .score{
            margin-top: 8px;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
            line-height: 21px;
          }
          .txt{
            margin-top: 12px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            line-break: anywhere;
          }
          .homework-list{
            .item-li{
              margin-top: 12px;
              border-radius: 8px;
              background: #FFFFFF;
              padding: 8px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              >div{
                img{
                  width: 50px;
                  margin-right: 16px;
                }
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
    .side-item:last-child{
      .line-l .line{
        display: none;
      }
    }
    .first-item{
      i{
        background: @theme;
      }
      h4{
        margin-top: -6px;
      }
    }
  }

  .sping{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 250px;
    margin-top: -10px;
    margin-left: -10px;
  }
}

.work-file{
  margin-top: 12px;
  padding: 22px 30px;
  background: #ffffff;
  border-radius: 5px;
  .inner{
    border: 1px solid #F2F2F2;
    border-radius: 10px;
    position: relative;
    .text1{
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
      margin-left: 16px;
      img{
        width: 13px;
        margin-right: 4px;
        margin-top: -2px;
      }
    }
    .top{
      padding: 16px 0;
      background: #FCFBFC;
      border-radius: 10px 10px 0px 0px;
    }
    .info-score{
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 21px;
      padding-top: 15px;
      padding-left: 35px;
    }
    .reply{
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 25px;
      padding: 16px 35px;
    }
    .home-wrok{
      margin: 0 34px 16px;
    }
    .icon-type{
      width: 88px;
      height: 88px;
      position: absolute;
      right: -15px;
      top: -16px;
    }
  }
  .time{
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 22px;
    .hours{
      margin-right: 60px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      i{
        color: #F90000;
        font-style: normal;
        margin-right: 4px;
      }
      span{
        margin-right: 4px;
        color: #666666;
      }
    }
    .disable{
      background: #83d5e7;
    }
  }
}
</style>
