<template>
  <div>
    <div class="home-file" v-if="list.length">
      <span class="file-name">{{text}}：</span>
      <div class="file-ul">
        <div class="file-li" title="点击可在线预览" @click="onOpenFile(item.url)" v-for="(item,index) in list" :key="index">
          <img :src="getSuffix(item.url)" alt="">
          <span>{{item.name}}</span>
          <span class="down" v-if="isDown" @click.stop="onDownLoad(item.url, item.name)">下载</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { openFile, getSuffix, downFile } from '@/unit/fun.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    list: { // 附件列表
      type: Array,
      default: () => [],
    },
    // 是支持下载 1.支持 0.不支持
    isDown: {
      type: null,
			default: 0
    },
    text: {
      type: String,
			default: '附件'
    }
  },
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {
    // 打开文件
    onOpenFile(url){
      openFile(url)
    },

    // 解析文件后缀返回对应图片
    getSuffix(string){
      return getSuffix(string)
    },

    // 下载文件
    onDownLoad(url, name) {
      downFile(url,name)
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.home-file{
  flex: 1;
  border-radius: 10px;
  margin-top: 8px;
  display: flex;
  padding: 8px 14px 8px 0;
  .file-name{
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 21px;
    margin-top: 4px;
  }
  .file-ul{
    flex: 1;
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 10px;
    .file-li{
      display: flex;
      align-items: center;
      margin-right: 12px;
      cursor: pointer;
      img{
        width: 32px;
        margin-right: 8px;
      }
      span{
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
      .down{
        font-size: 14px;
        margin-left: 10px;
        color: @theme;
      }
      &::after{
        content: '';
        display: inline-block;
        width: 1px;
        height: 14px;
        margin-left: 12px;
        background: #F2F2F2;
      }
    }
    .file-li:last-child::after{
      background: none;
      margin-left: 0;
    }
  }
  /*滚动条样式*/
  .file-ul::-webkit-scrollbar { //滚动条的宽度
    height: 4px;
  }
  /* 滚动槽 */
  .file-ul::-webkit-scrollbar-track {
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
    border-radius:0;
    background:rgba(255, 255, 255, 0.1);
  }
  /* 滚动条滑块 */
  .file-ul::-webkit-scrollbar-thumb {
    border-radius:10px;
    background:rgba(0,0,0, .1);
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
  }
}
</style>
